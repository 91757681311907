<style scoped>
.map-container-meters {
  height: 585px;
}

.send-command-button {
  width: 15ch;
}

.command-palette-container {
  display: grid;
  align-items: start;
  grid-template-columns: repeat(auto-fit, minmax(27ch,1fr));
  gap: 2.5rem;
}
</style>
<template>
  <div>
    <b-row>
      <b-col md="12" class="text-right mb-2" v-if="userRole !== 'I'">
        <b-button
          variant="primarySpore"
          class="btn-icon"
          @click="showConfig = !showConfig"
          :disabled="parseInt(response.status) === 2"
        >
          <feather-icon icon="SettingsIcon" /> Comandos a Medidor
        </b-button>
      </b-col>
    </b-row>
    <b-card
      v-if="showConfig"
      id="command-palette"
    >
        <div class="command-palette-container">
          <div>
            <h4>Abrir relevador</h4>
            <b-button
              variant="primarySpore"
              class="btn-icon"
              @click="sendComand(response.uuid, '50', '00')"
            >
              <feather-icon icon="SettingsIcon" /> Solicitar
            </b-button>
          </div>
          <div>
            <h4>Cerrar relevador</h4>
            <b-button
              variant="primarySpore"
              class="btn-icon"
              @click="sendComand(response.uuid, '60', '00')"
            >
              <feather-icon icon="SettingsIcon" /> Solicitar
            </b-button>
          </div>
          <div>
            <h4>Obtener Identificación:</h4>
            <b-button
              variant="primarySpore"
              class="btn-icon"
              @click="sendComand(response.uuid, '10', '00')"
            >
              <feather-icon icon="SettingsIcon" /> Solicitar
            </b-button>
          </div>
          <div>
            <h4>Lecturas de Energia:</h4>
            <b-button
              variant="primarySpore"
              class="btn-icon"
              @click="sendComand(response.uuid, '20', '00')"
            >
              <feather-icon icon="SettingsIcon" /> Solicitar
            </b-button>
          </div>
          <div>
            <h4>Obtener Fase A:</h4>
            <b-button
              variant="primarySpore"
              class="btn-icon"
              @click="sendComand(response.uuid, '30', '00')"
            >
              <feather-icon icon="SettingsIcon" /> Solicitar
            </b-button>
          </div>
          <div>
            <h4>Obtener Fase C:</h4>
            <b-button
              variant="primarySpore"
              class="btn-icon"
              @click="sendComand(response.uuid, '40', '00')"
            >
              <feather-icon icon="SettingsIcon" /> Solicitar
            </b-button>
          </div>
          <div>
            <h4>Reiniciar Tarjeta:</h4>
            <b-button
              variant="primarySpore"
              class="btn-icon"
              @click="sendComandSimulated('reinicio')"
            >
              <feather-icon icon="SettingsIcon" /> Solicitar
            </b-button>
          </div>
          <div>
            <h4>Cambiar Mono/Bi fase</h4>
            <b-button
              variant="primarySpore"
              class="btn-icon"
              @click="sendComand(response.uuid, 'A0', '00')"
            >
              <feather-icon icon="SettingsIcon" /> Solicitar
            </b-button>
          </div>
          <div>
            <h4>
              Tiempo de Respuesta:
            </h4>
            <div class="d-flex align-items-center command-col">
              <v-select
                class="w-100"
                v-model="timeResponse"
                :options="opcTime"
                :clearable="false"
              />
              <b-button
                variant="primarySpore"
                class="btn-icon send-command-button"
                @click="sendComand(response.uuid, '01', timeResponse.value)"
              >
                <feather-icon icon="SendIcon" /> Enviar
              </b-button>
            </div>
          </div>
          <div>
            <h4>
              Tiempo de Reporteo:
            </h4>
            <div class="d-flex align-items-center command-col">
            <!--
              <b-form-input
                class="w-100"
                type="number"
                min="0"
                max="60000"
                :formatter="maximo"
                v-model="timeReport"
                @keypress="validaNumero"
              />
              -->
              <v-select
                class="w-100"
                v-model="timeReport"
                :options="opcTimeReporteo"
                :clearable="false"
              />
              <b-button
                variant="primarySpore"
                class="btn-icon send-command-button"
                :disabled="timeReport == ''"
                @click="sendComand(response.uuid, timeReport.value, '00')"
              >
                <feather-icon icon="SendIcon" /> Enviar
              </b-button>
            </div>
          </div>
          <div>
            <h4>Petición de interrupción:</h4>
            <b-button
              variant="primarySpore"
              class="btn-icon"
              @click="sendComandSimulated('interrupción')"
            >
              <feather-icon icon="SettingsIcon" /> Solicitar
            </b-button>
          </div>
          <div>
            <h4>Programación:</h4>
            <b-button
              variant="primarySpore"
              class="btn-icon"
              @click="sendComandSimulated('programación')"
            >
              <feather-icon icon="SettingsIcon" /> Solicitar
            </b-button>
          </div>
          <div>
            <h4>
              Cambiar Fase
            </h4>
            <div class="d-flex align-items-center command-col">
              <v-select
                class="w-100"
                v-model="changeFaseType"
                :options="opcChangeFaseType"
                :clearable="false"
              />
              <b-button
                variant="primarySpore"
                class="btn-icon send-command-button"
                :disabled="changeFaseType == ''"
                @click="sendComandSimulated('cambio de fase')"
              >
                <feather-icon icon="SendIcon" /> Enviar
              </b-button>
            </div>
          </div>
        </div>
    </b-card>
    <b-row id="meter-stats-header" align-v="stretch" class="">
      <b-col sm="12" lg="6">
        <b-card>
          <b-row>
            <b-col md="6">
              <h4 class="mb-1">Número de medidor</h4>
            <div style="display: flex; gap: 1rem; margin-bottom: 1rem;">
              <div>
                <feather-icon icon="CpuIcon" size="30"></feather-icon>
              </div>
              <div>
                <p class="h5">{{ response.number }}</p>
                <p class="h6" style="margin-top: -0.5rem">{{ response.meterInstalled }}</p>
              </div>
            </div>
            </b-col>
            <b-col align-self="center" md="6">
              <b-button
                variant="primarySpore"
                class="btn-icon"
                @click="showModalFOTA = true"
              >
                Enviar FOTA
              </b-button>
              <b-modal v-model="showModalFOTA" size="sm" centered hide-header-close no-close-on-esc no-close-on-backdrop>
                <template #modal-title>
                  <h4>Subir archivo</h4>
                </template>
                <p class="text-dark">Cargar Archivo Firmware</p>
                <input type="file" ref="fileFOTA" class="mw-100"   />
                <template #modal-footer="{ cancel }">
                  <b-button
                    variant="primarySpore"
                    @click="handleSaveFOTA"
                  >
                    Enviar
                  </b-button>
                  <b-button
                    variant="danger"
                    @click="cancel"
                  >
                    Cancelar
                  </b-button>
                </template>
              </b-modal>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" class="mb-1 mt-1">
              <h4>Tipo de medidor:</h4>
              <label class="font-medium-1" style="font-size: 20px">
              {{ METER_TYPES[response.type] || 'Desconocido' }}</label>
            </b-col>
            <b-col md="6" class="my-1">
              <h4>Fecha instalación:</h4>
              <label class="font-medium-1">{{
                response.installedAt
              }}</label>
            </b-col>
            <b-col md="6" class="my-1">
              <h4>Fecha última lectura:</h4>
              <label class="font-medium-1" style="font-size: 20px">{{
                response.transmitedAt
              }}</label>
            </b-col>
            <b-col md="6" class="my-1">
              <h4>Señal:</h4>
              <feather-icon icon="RadioIcon" size="25" />
              <span class="align-middle">
                {{ response.signal }}
              </span>
            </b-col>
            <b-col md="6" class="my-1">
              <h4>Contador de reinicios:</h4>
              <label class="font-medium-1">{{
                response.counterRestart
              }}</label>
            </b-col>
            <b-col md="6" class="my-1">
              <h4>Número Contrato:</h4>
              <label class="font-medium-1">{{
                response.contract
              }}</label>
            </b-col>
            <b-col md="6" class="mb-1 mt-1">
              <h4>Serie:</h4>
              <spore-serie :serie="response.serie"></spore-serie>
            </b-col>
            <b-col md="6" class="mb-1 mt-1">
              <h4>Serie tarjeta:</h4>
              <label class="font-medium-1" style="font-size: 20px">{{
                response.serieCard
              }}</label>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col sm="12" lg="6">
        <b-row align-v="stretch">
          <b-col sm="6">
            <b-card>
              <statistic-card-vertical
                :statistic="corteStatusMessage"
                icon="PowerIcon"
                statistic-title="Relevador"
              />
            </b-card>
          </b-col>
          <b-col sm="6">
            <b-card>
              <statistic-card-vertical
                :icon="transmissionStatusIndicator.icon"
                :statistic="transmissionStatusIndicator.message"
                statistic-title="Estado"
                :color="transmissionStatusIndicator.color"
              />
            </b-card>
          </b-col>
          <b-col sm="6">
            <b-card>
              <statistic-card-vertical
                icon="BatteryChargingIcon"
                :statistic="response.energy + ' Kw'"
                statistic-title="Consumo"
                color="primary"
              />
            </b-card>
          </b-col>
          <b-col sm="6">
            <b-card>
              <statistic-card-vertical
                icon="BatteryChargingIcon"
                :statistic="response.energyR + ' Kw'"
                statistic-title="Energía Reactiva"
                color="primary"
              />
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
      <!-- <b-col lg="4" sm="12">
        <b-card>
          <statistic-card-vertical
            icon="CpuIcon"
            :statistic="response.meterNumber"
            statistic-title="Número medidor"
            color="primary"
            />
        </b-card>
      </b-col> -->
    <b-row id="measurements">
      <!-- Fase A -->
      <b-col lg="3" sm="12">
        <b-card>
          <statistic-card-vertical
            icon="CloudLightningIcon"
            :statistic="response.currentA + ' A'"
            statistic-title="Corriente A"
            color="success"
          />
        </b-card>
      </b-col>
      <b-col lg="3" md="12">
        <b-card class="card_statisc-meters">
          <statistic-card-vertical
            icon="ZapIcon"
            :statistic="decimal(response.voltageA) + ' V'"
            statistic-title="Voltaje A"
            color="success"
          />
        </b-card>
      </b-col>
      <b-col lg="3" md="12">
        <b-card class="card_statisc-meters">
          <statistic-card-vertical
            icon="LoaderIcon"
            :statistic="decimal(response.potencyA) + ' W'"
            statistic-title="Potencia A"
            color="info"
          />
        </b-card>
      </b-col>
      <b-col lg="3" md="12">
        <b-card class="card_statisc-meters">
          <statistic-card-vertical
            icon="ActivityIcon"
            :statistic="decimal(response.frecA) + ' Hz'"
            statistic-title="Frecuencia A"
            color="warning"
          />
        </b-card>
      </b-col>
      <!-- Fase C-->
      <b-col lg="3" md="12">
        <b-card>
          <statistic-card-vertical
            icon="CloudLightningIcon"
            :statistic="decimal(response.currentC) + ' A'"
            statistic-title="Corriente C"
            color="success"
          />
        </b-card>
      </b-col>
      <b-col lg="3" md="12">
        <b-card class="card_statisc-meters">
          <statistic-card-vertical
            icon="ZapIcon"
            :statistic="decimal(response.voltageC) + ' V'"
            statistic-title="Voltaje C"
            color="success"
          />
        </b-card>
      </b-col>
      <b-col lg="3" md="12">
        <b-card class="card_statisc-meters">
          <statistic-card-vertical
            icon="LoaderIcon"
            :statistic="decimal(response.potencyC) + ' W'"
            statistic-title="Potencia C"
            color="info"
          />
        </b-card>
      </b-col>
      <b-col lg="3" md="12">
        <b-card class="card_statisc-meters">
          <statistic-card-vertical
            icon="ActivityIcon"
            :statistic="decimal(response.frecC) + ' Hz'"
            statistic-title="Frecuencia C"
            color="warning"
          />
        </b-card>
      </b-col>
      <!-- Fase B -->
      <b-col lg="3" md="12">
        <b-card>
          <statistic-card-vertical
            icon="CloudLightningIcon"
            :statistic="decimal(response.currentB) + ' A'"
            statistic-title="Corriente B"
            color="success"
          />
        </b-card>
      </b-col>
      <b-col lg="3" md="12">
        <b-card class="card_statisc-meters">
          <statistic-card-vertical
            icon="ZapIcon"
            :statistic="decimal(response.voltageB) + ' V'"
            statistic-title="Voltaje B"
            color="success"
          />
        </b-card>
      </b-col>
      <b-col lg="3" md="12">
        <b-card class="card_statisc-meters">
          <statistic-card-vertical
            icon="LoaderIcon"
            :statistic="decimal(response.potencyB) + ' W'"
            statistic-title="Potencia B"
            color="info"
          />
        </b-card>
      </b-col>
      <b-col lg="3" md="12">
        <b-card class="card_statisc-meters">
          <statistic-card-vertical
            icon="ActivityIcon"
            :statistic="decimal(response.frecB) + ' Hz'"
            statistic-title="Frecuencia B"
            color="warning"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row
      v-if="
        responseImage.image1 || responseImage.image2 || responseImage.image3
      "
    >
      <b-col md="12">
        <b-card>
          <b-card-header>
            <b-card-title>Evidencias de la instalación</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col lg="4" md="4" v-if="responseImage.image1">
                <b-img
                  thumbnail
                  fluid
                  :src="`data:image/png;base64,${responseImage.image1}`"
                />
              </b-col>
              <b-col lg="4" md="4" v-if="responseImage.image2">
                <b-img
                  thumbnail
                  fluid
                  :src="`data:image/png;base64,${responseImage.image2}`"
                />
              </b-col>
              <b-col lg="4" md="4" v-if="responseImage.image3">
                <b-img
                  thumbnail
                  fluid
                  :src="`data:image/png;base64,${responseImage.image3}`"
                />
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-card-body>
            <b-row>
              <b-col md="10">
                <h4>Ubicación</h4>
              </b-col>
              <b-col md="12">
                <div
                  class="map-container-meters basic-example"
                  v-if="status_mapa"
                >
                  <l-map
                    :center="center"
                    :zoom="zoom"
                    v-if="response"
                    :options="{ attributionControl: false, fullscreenControl: true, zoomControl: false }"
                  >
                    <l-control-layers
                      position="topright"
                      :collapsed="true"
                      :sort-layers="true"
                    />
                    <l-control-zoom position="topleft" :options="{ zoomInTitle: 'Acercar', zoomOutTitle: 'Alejar' }"/>
                    <l-tile-layer
                      v-for="tileProvider in mapTileLayers"
                      :key="tileProvider.url"
                      :name="tileProvider.name"
                      :visible="tileProvider.visible"
                      :url="tileProvider.url"
                      layer-type="base"
                    />
                    <l-control-attribution
                      position="bottomright"
                      prefix="Spore Cloud"
                    />
                    <l-marker :lat-lng="marker" ref="position_marker">
                      <l-icon :icon-anchor="staticAnchor">
                        <b-img
                          rounded
                          :src="require(`@/assets/images/markers/0.svg`)"
                          height="50"
                        />
                      </l-icon>
                    </l-marker>
                  </l-map>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <b-card-header>
            <b-card-title>Últimos Comandos</b-card-title>
          </b-card-header>
          <b-card-body class="sizeStatic-command" style="overflow-y:scroll">
            <b-row>
              <b-col md="12" class="text-center" v-if="responseCommand.length === 0">
                <h5>No hay comandos en el Medidor</h5>
              </b-col>
            </b-row>
            <b-table
              class="position-relative"
              hover
              responsive
              primary-key="id"
              :items="responseCommand"
              :fields="tableColumnsCommand"
            >
              <template #cell(command)="data">
                {{ commandsDictionary[data.value] || 'Desconocido' }}
              </template>
              <template #cell(rpe)="data">
                {{ data.item.cfeUser && data.item.cfeUser.rpe}}
              </template>
              <template #cell(origen)="data">
                {{ data.value === 'P' ? 'Plataforma' : 'Sinamed' }}
              </template>
              <template #cell(createdAt)="data">
                {{  new Date(data.value).toLocaleString('es-MX') }}
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card>
          <b-card-header>
            <b-card-title>Últimas lecturas</b-card-title>
          </b-card-header>
          <b-card-body class="sizeStatic-meters" style="overflow-y: scroll">
            <b-row>
              <b-col
                md="12"
                class="text-center"
                v-if="responseLog.length === 0"
              >
                <h5>No hay lecturas en el Medidor</h5>
              </b-col>
            </b-row>
            <b-table
              class="position-relative"
              hover
              responsive
              primary-key="id"
              :items="responseLog"
              :fields="tableColumnsLog"
            >
              <template #cell(Transmited)="data">
                <div class="text-nowrap selectable" v-if="data.item.createdAt">
                  <b-avatar
                    v-if="data.item.createdAt"
                    size="32"
                    class="avatar-border-2 box-shadow-1"
                    variant="transparent"
                  >
                    <b-img
                      :src="require(`@/assets/images/spore-icons/fecha.svg`)"
                      height="20"
                    />
                  </b-avatar>
                  <span class="align-text-top text-capitalize ml-1">{{
                    new Date(data.item.createdAt).toLocaleString('es-MX')
                  }}</span>
                </div>
              </template>
              <template #cell(Energy)="data">
                {{ data.item.energy }} Kw
              </template>
            </b-table>
          </b-card-body>
          <b-row>
            <b-col md="12" class="text-center">
              <b-link :to="`/meters/GWH0079/${uuid}/logs`">Ver más</b-link>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BImg,
  BCardTitle,
  BCardHeader,
  BTable,
  BAvatar,
  BButton,
  BLink,
  BModal
} from 'bootstrap-vue'
import request from '@/libs/request/index'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { formatDateLanguages, empty } from '@/libs/tools/helpers'
import SporeSerie from './SporeSerie.vue'
import commandsDictionary from './commands-079'
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LControlAttribution,
  LControlLayers,
  LControlZoom
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen'

const METER_TYPES = {
  0: 'Medidor Monofásico Especificación GWH00-09',
  1: 'Medidor Bifásico Especificación GWH00-09',
  2: 'Medidor Trifásico Especificación GWH00-09',
  3: 'Medidor Monofásico Especificación GWH00-34',
  4: 'Medidor Bifásico Especificación GWH00-34'
}

/* global L:true */
export default {
  components: {
    SporeSerie,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LControlAttribution,
    LControlLayers,
    BRow,
    BCol,
    BCard,
    LControlZoom,
    BCardBody,
    StatisticCardVertical,
    BImg,
    BCardTitle,
    BCardHeader,
    BTable,
    BAvatar,
    BButton,
    BLink,
    BModal
  },
  data () {
    return {
      METER_TYPES,
      commandsDictionary,
      mapTileLayers: [
        {
          name: 'Calles',
          visible: true,
          url: 'https://{s}.tile.openstreetmap.fr/osmfr//{z}/{x}/{y}.png'
        },
        {
          name: 'Gris',
          visible: false,
          url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
        },
        {
          name: 'Satelite',
          visible: false,
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png'
        }
      ],
      userRole: this.$store.state.session.AppActiveUser().userRole.toString(),
      uuid: this.$route.params.uuid,
      response: [],
      responseLog: [],
      responseCommand: [],
      commandPending: [],
      responseImage: [],
      opcMeterType: [],
      timeReport: '',
      changeFaseType: '',
      timeResponse: [],
      zoom: 17,
      center: L.latLng(47.41461, -1.206607),
      url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
      attribution: '',
      marker: L.latLng(47.41461, -1.206607),
      staticAnchor: [12, 27],
      status_mapa: false,
      showConfig: false,
      relay: false,
      showModalFOTA: false,
      status: 0,
      relayData: '',
      tableColumnsLog: [
        { key: 'Transmited', label: 'Última transmisión', sortable: true },
        { key: 'Energy', label: 'Consumo', sortable: true }

      ],
      tableColumnsCommand: [
        { key: 'command', label: 'Comando', sortable: true },
        { key: 'rpe', label: 'RPE', sortable: true },
        { key: 'origen', label: 'Origen', sortable: true },
        { key: 'createdAt', label: 'Fecha', sortable: false }
      ],
      filters: {
        limit: 10,
        offset: 0,
        byColumn: 'trasmitedAt',
        byOrder: 'DESC',
        timezoneOffset: ''
      },
      opcChangeFaseType: [
        {
          label: 'Monofasico',
          title: 'Monofasico',
          value: 'Monofasico'
        },
        {
          label: 'Bifasico',
          title: 'Bifasico',
          value: 'Bifasico'
        },
        {
          label: 'Trifasico',
          title: 'Trifasico',
          value: 'Trifasico'
        }
      ],
      opcTimeReporteo: [
        {
          label: '1 Minuto',
          title: '1 Minuto',
          value: '9905A0'
        },
        {
          label: '3 Minutos',
          title: '3 Minutos',
          value: '9901E0'
        },
        {
          label: '5 Minutos',
          title: '5 Minutos',
          value: '990120'
        },
        {
          label: '10 Minutos',
          title: '10 Minutos',
          value: '990090'
        },
        {
          label: '1 Hora',
          title: '1 Hora',
          value: '990018'
        },
        {
          label: '4 Horas',
          title: '4 Horas',
          value: '990006'
        },
        {
          label: '6 Horas',
          title: '6 Horas',
          value: '990004'
        },
        {
          label: '8 Horas',
          title: '8 Horas',
          value: '990003'
        }
      ],
      opcTime: [
        {
          label: 'Sin asignar',
          title: 'Sin asignar',
          value: '0'
        },
        {
          label: 'Cada 24 horas',
          title: 'Cada 24 horas',
          value: '1'
        },
        {
          label: 'Cada 12 horas',
          title: 'Cada 12 horas',
          value: '2'
        },
        {
          label: 'Cada 8 horas',
          title: 'Cada 8 horas',
          value: '3'
        },
        {
          label: 'Cada 6 horas',
          title: 'Cada 6 horas',
          value: '4'
        },
        {
          label: 'Cada 4 horas',
          title: 'Cada 4 horas',
          value: '6'
        },
        {
          label: 'Cada 2 horas',
          title: 'Cada 2 horas',
          value: '12'
        },
        {
          label: 'Cada 1 hora',
          title: 'Cada 1 hora',
          value: '24'
        }
      ]
    }
  },
  computed: {
    corteStatusMessage () {
      if (this.response.relayState === '50') return 'Abierto'
      if (this.response.relayState === '60') return 'Cerrado'
      return 'Cerrado'
    },
    transmissionStatusIndicator () {
      const status = this.response.transmissionStatus
      return {
        color: status === 0 ? 'success' : status === 1 ? 'warning' : 'secondary',
        message: status === 0 ? 'Transmitiendo' : status === 1 ? 'Más de un día s/T' : 'Sin transmitir',
        icon: status === 0 ? 'CloudIcon' : 'CloudOffIcon'
      }
    }
  },
  methods: {
    maximo (e) {
      return String(e).substring(0, 5)
    },
    formatEnergyUnit (value) {
      // Para formatear los valores de corriente, voltaje y potencia
      if (value === null) {
        return '0.00'
      }

      return (Number(value) / 1000).toFixed(2)
    },
    validaNumero: event => {
      // eslint-disable-next-line prefer-const
      let keyCode = event.keyCode
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault()
      }
    },
    decimal (val, n) {
      return Number(val).toFixed(2)
    },
    redirect (uuid) {
      if (!empty(uuid)) {
        this.$router.push(`/gabinets/${uuid}/detail`)
      }
    },
    redirectSinamed (uuid) {
      if (!empty(uuid)) {
        this.$router.push(`/meters/GWH0079/${uuid}/sinamedLogs`)
      }
    },
    format (date) {
      return formatDateLanguages(date, 'hour')
    },
    isCommandPending (...commandCodes) {
      return (
        this.commandPending.filter(entry => commandCodes.includes(entry.command))
          .length > 0
      )
    },
    async getDetail () {
      const params = {
        url: `/meters079/${this.uuid}`,
        method: 'GET'
      }

      try {
        const { data } = await request(params)
        const responseData = data.data

        this.response = {
          meterNumber: responseData?.meterNumber ?? '',
          meterInstalled: responseData?.meterInstalled ?? '',
          uuid: responseData.uuid,
          createdAt: responseData.createdAt,
          transmitedAt: !empty(responseData.trasmitedAt)
            ? this.format(responseData.trasmitedAt)
            : '-',
          installedAt: !empty(responseData.installedAt)
            ? this.format(responseData.installedAt)
            : '-',
          serie: !empty(responseData.serie) ? responseData.serie : '',
          serieCard: responseData?.serieCard ?? '',
          contract: responseData?.contract ?? '',
          number: responseData.meterNumber?.replace(/^(0)+/, ''),
          type: responseData.typeTecnology ? responseData.typeTecnology : '',
          signal: !empty(responseData.signal)
            ? responseData.signal
            : 'Sin señal',
          updatedAt: responseData.updatedAt,
          status: !empty(responseData.status) ? responseData.status : '0',
          transmissionStatus: responseData.transmissionStatus,
          latlong: responseData.latlong,
          energy: responseData?.energy ?? '0', // Consumo
          energyR: responseData?.energyR ?? '0', // Energía Reactiva
          currentA: this.formatEnergyUnit(responseData.currentA),
          currentB: this.formatEnergyUnit(responseData.currentB),
          currentC: this.formatEnergyUnit(responseData.currentC),
          frecA: this.formatEnergyUnit(responseData.frecA),
          frecB: this.formatEnergyUnit(responseData.frecB),
          frecC: this.formatEnergyUnit(responseData.frecC),
          voltageA: this.formatEnergyUnit(responseData.voltageA),
          voltageB: this.formatEnergyUnit(responseData.voltageB),
          voltageC: this.formatEnergyUnit(responseData.voltageC),
          potencyA: this.formatEnergyUnit(responseData.potencyA),
          potencyB: this.formatEnergyUnit(responseData.potencyB),
          potencyC: this.formatEnergyUnit(responseData.potencyC),
          counterRestart: responseData.counterRestart,
          relayState: responseData.relayState
        }
        this.responseCommand = data.logCommand
        this.commandPending = []
        this.status = this.response.status === '0' ? 50 : 60
        this.getMeterLogs()
        this.relay = parseInt(responseData.status) === 1

        if (!empty(responseData.latlong)) {
          const [lat, long] = responseData.latlong.split(',')
          this.marker = L.latLng(lat, long)
          this.center = L.latLng(lat, long)
        }

        this.timeResponse = !empty(responseData.timeResponse)
          ? this.opcTime.filter(el => el.value === responseData.timeResponse)
          : this.opcTime.filter(el => el.value === '0')
        // this.timeReport = !empty(responseData.time) ? responseData.time : '0'
        this.status_mapa = true
      } catch (err) {
        // Handle error
        console.log('Error on getDetail', err)
      }
    },
    async getMeterLogs () {
      this.responseLog = []
      this.filters.timezoneOffset = new Date().getTimezoneOffset()
      const params = {
        url: `meters079/log/${this.uuid}`,
        method: 'GET',
        params: this.filters
      }
      const logs = await request(params)
      this.responseLog = logs.data.data.rows.map(log => ({
        trasmitedAt: log.trasmitedAt,
        createdAt: log.createdAt,
        energy: log.energy
      }))
    },
    async getMeterType () {
      this.opcMeterType = []
      const params = {
        url: '/meters079/tecnologies/all',
        method: 'GET'
      }
      await request(params).then(data => {
        for (let index = 0; index < data.data.data.length; index++) {
          this.opcMeterType.push({
            label: data.data.data[index].name,
            title: data.data.data[index].name,
            value: data.data.data[index].enum
          })
        }
      })
    },
    async getImage () {
      const params = {
        url: `/meters079/images/${this.uuid}`,
        method: 'GET'
      }
      await request(params).then(data => {
        this.responseImage = data.data.data
      })
    },
    sendComand (uuid, command, time) {
      this.$swal({
        title: '¿Estas seguro?',
        text: 'Estos comandos se verán reflejados una vez que el dispositivo transmita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `/meters079/measurements/plataform/${uuid}/${command}/${time}`,
            method: 'POST'
          }
          await request(params).then(data => {})
        }
        this.getDetail()
        this.status = this.response.status === '0' ? 50 : 60
        this.showConfig = false
      })
    },
    sendComandSimulated (type) {
      this.$swal({
        title: '¿Estas seguro?',
        text: 'Estos comandos se verán reflejados una vez que el dispositivo transmita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        this.$swal({
          title: 'Correcto',
          text: 'Comando de ' + type + ' mandado correctamente',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          }
        })
      })
    },
    handleSaveFOTA () {
      const file = this.$refs.fileFOTA.files[0]
      if (!file) {
        this.$swal({
          title: 'Error',
          text: 'No se ha seleccionado un archivo',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Aceptar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          }
        })
        return
      }
      this.showModalFOTA = false
      this.$swal({
        title: 'Correcto',
        text: 'FOTA mandado correctamente',
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        }
      })
    }
  },
  created () {
    this.getDetail()
    this.getImage()
    this.getMeterType()
  }
}
</script>
<style scoped>

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
