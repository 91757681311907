/* eslint-disable quote-props */
export default {
  '01': 'Tiempo de respuesta medidor',
  '02': 'Frecuencia de reporteo',
  '03': 'Mensaje no reconocido',
  '04': 'Confirmación reinicio',
  '05': 'Interrupciones de energía',
  '06': 'Valores programados',
  '10': 'Identificador de medidor',
  '20': 'Reporte de Energía',
  '30': 'Instantáneos fase A',
  '40': 'Instantáneos fase C',
  '60': 'Cierre de relevador',
  '50': 'Apertura de relevador',
  '90': 'Estado medidor',
  'B0': 'Error de comunicación',
  'C0': 'Número de medidor erróneo',
  'D0': 'Nula comunicación',
  '70': 'Reporte demandas',
  '80': 'Reporte demandas mensuales',
  'A0': 'Modalidad de medidor',
  'E0': 'Cambio a prepago',
  'F0': 'Cambio a pospago',
  '11': 'Cambio normal',
  '21': 'Cambio bidireccional',
  '31': 'Energía ambos sentidos',
  '41': 'Demandas ambos sentidos',
  '51': 'Instantáneos fase B',
  '61': 'Consumo mensual',
  '71': 'Cambio unidireccional',
  '81': 'Frecuencias'
}
